import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface IVendorLandingFormProps {
    model: any
}

const VendorLandingForm: FC<IVendorLandingFormProps> = observer(({ model }) => {
  return (
    <div className="action_block d-flex align-items-end flex-column gap-3 mt-5">
        <select
            onChange={(e) => model.setSelectedCategory((e.target as HTMLSelectElement).value)}
            value={model.selectedCategory}
            placeholder='Категории'
        >
            {model.categories.map((category: any) => (
                <option value={category.slug} key={category.id}>
                    <FormattedMessage id={`vendors.categories.${category.translation_key}`}
                                        defaultMessage={category.name_default}>
                        {(text: string) => text}
                    </FormattedMessage>
                </option>
            ))}
        </select>
        <select
            onChange={(e) => model.setSelectedLocation((e.target as HTMLSelectElement).value)}
            value={model.selectedLocation}
            placeholder='Локации'
        >
            {model.locations.map((location: any) => (
                <option value={location.slug} key={location.id}>
                    <FormattedMessage id={`locations.${location.translation_key}`}
                                        defaultMessage={location.name_default}>
                        {(text: string) => text}
                    </FormattedMessage>
                </option>
            ))}
        </select>
        <button onClick={() => model.redirectToNewPage()} className='black_button mt-3'>Поиск</button>
    </div>
  )
})

export default VendorLandingForm
