import React, { Component } from 'react'
import SuppliersList from '../suppliers/SuppliersList'
import VendorsSearchModel from './model/VendorsSearchModel'
import SortButton from './SortButton'
import { Vendor } from './types/Vendor.type'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'
import VendorsServiceList from './VendorsServiceList'

interface VendorsSearchAppProps {
    vendors: Vendor[];
    locale: string;
    location: any;
    category: any;
    totalPages: number
}

export default class VendorsSearchApp extends Component<VendorsSearchAppProps> {
  model
  locale: string

  constructor (props: VendorsSearchAppProps) {
    super(props)
    this.model = new VendorsSearchModel({
      vendors: props.vendors,
      location: props.location,
      category: props.category,
      totalPages: props.totalPages
    })

    this.locale = props.locale
  }

  render () {
    return (
        <TranslationProvider user_locale={this.locale}>
            <div className='search_vendor'>
                <SuppliersList popularVendors={[{ name: 'Blah ha' }]}/>
                <div className="brand_content_line mt-5 mb-5"></div>
                <div className="search_header mt-3 mt-md-0 d-flex justify-content-between">
                    <div className='d-flex mb-3 mb-md-0 flex-column flex-md-row gap-3 align-items-center flex-wrap'>
                        <select className='search_select' name="" id="">
                        <FormattedMessage tagName="option" id={`vendors.categories.${this.model.category.translation_key}`} defaultMessage={this.model.category.name_default} />
                        </select>
                        <select className='search_select' name="" id="">
                        <FormattedMessage tagName="option" id={`locations.${this.model.location.translation_key}`} defaultMessage={this.model.location.name_default} />
                        </select>
                        <div className="black_button">Поиск</div>
                    </div>
                    <SortButton model={this.model}/>
                </div>
                <VendorsServiceList model={this.model} />
                {/* <div className="ad_banner mt-5 w-100">
                    <div className="ad_banner_content p-4 w-100">
                        <h1>
                            Лучшие флористы города Алматы!
                            <p>Скидка новым клиентами 20%</p>
                        </h1>
                    </div>
                    <div className="promo_info pt-1 pb-1">
                        Промо
                    </div>
                </div> */}
            </div>
        </TranslationProvider>
    )
  }
}
