import { action, computed, makeObservable, observable } from 'mobx'
import { successfully_vendor_leads_path, vendor_leads_path } from '../../../../routes.js.erb'

class VendorPageModel {
  userInput
  vendorInfo
  locale
  serverPath
  serverMethod
  isGalleryOpen
  isNameEmpty
  isPhoneEmpty
  isCommentEmpty
  isFileLarge
  isFileExtForbidden
  tempNumberRegex
  saveButtonDisabled
  files
  orderFileUploadEnabled

  constructor ({ vendorID, locale, defaultCountryCode, defaultPhoneCountry, vendorInfo, orderFileUploadEnabled }) {
    if (vendorID) {
      this.userInput = {
        name: '',
        vendor_id: vendorID,
        countryCode: defaultCountryCode || '+1',
        phone: '',
        comment: ''
      }
      this.files = []
      this.serverPath = vendor_leads_path(locale, vendorID)
      this.successfullyPath = successfully_vendor_leads_path(locale, vendorID)
    }

    this.defaultPhoneCountry = defaultPhoneCountry
    this.vendorInfo = vendorInfo
    this.orderFileUploadEnabled = orderFileUploadEnabled || false

    this.serverMethod = 'POST'
    this.isGalleryOpen = false
    this.isNameEmpty = false
    this.isPhoneEmpty = false
    this.isCommentEmpty = false
    this.isFileLarge = false
    this.isFileExtForbidden = false
    this.tempNumberRegex = /^[0-9]*$/
    this.saveButtonDisabled = false
    this.locale = locale

    makeObservable(this, {
      userInput: observable,
      files: observable,
      isGalleryOpen: observable,
      isNameEmpty: observable,
      isPhoneEmpty: observable,
      isCommentEmpty: observable,
      isFileLarge: observable,
      isFileExtForbidden: observable,
      saveButtonDisabled: observable,
      orderFileUploadEnabled: observable,
      leadChangeFiles: action,
      setIsNameEmpty: action,
      setIsPhoneEmpty: action,
      setIsCommentEmpty: action,
      setIsFileLarge: action,
      setIsFileExtForbidden: action,
      setIsGalleryOpen: action,
      leadChangeName: action,
      leadChangeCountryCode: action,
      leadChangePhone: action,
      leadChangeComment: action,
      clearFiles: action,
      isInfoEmpty: computed
    })
  }

  changeSaveButtonDisabled (new_status) {
    this.saveButtonDisabled = new_status
  }

  setIsGalleryOpen (isOpen) {
    this.isGalleryOpen = isOpen
  }

  leadChangeName (name) {
    this.userInput.name = name
  }

  leadChangeCountryCode (countryCode) {
    this.userInput.countryCode = countryCode
  }

  leadChangePhone (phone) {
    if (this.tempNumberRegex.test(phone)) {
      this.userInput.phone = phone
    }
  }

  leadChangeComment (comment) {
    if (comment.length <= 250) {
      this.userInput.comment = comment
    }
  }

  setIsNameEmpty (isEmpty) {
    this.isNameEmpty = isEmpty
  }

  setIsPhoneEmpty (isEmpty) {
    this.isPhoneEmpty = isEmpty
  }

  setIsCommentEmpty (isEmpty) {
    this.isCommentEmpty = isEmpty
  }

  setIsFileLarge (isLarge) {
    this.isFileLarge = isLarge
  }

  setIsFileExtForbidden (isExtForbidden) {
    this.isFileExtForbidden = isExtForbidden
  }

  clearFiles () {
    this.files = []
    this.setIsFileLarge(false)
    this.setIsFileExtForbidden(false)
  }

  leadChangeFiles (files) {
    const MAX_FILE_SIZE_MB = 15
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024
    const FORBIDDEN_EXTENSIONS = ['exe', 'js', 'py', 'jar', 'bat']

    this.setIsFileLarge(false)
    this.setIsFileExtForbidden(false)
    this.files = files

    for (let i = 0; i < files.length; i++) {
      if (files[i] == undefined) {
        this.setIsFileLarge(false)
        this.files[i] = null
      } else if (files[i].size > MAX_FILE_SIZE_BYTES) {
        this.setIsFileLarge(true)
        this.files = []
      } else {
        const fileExtension = files[i].name.split('.').pop().toLowerCase()
        if (FORBIDDEN_EXTENSIONS.includes(fileExtension)) {
          this.setIsFileExtForbidden(true)
          this.files = []
          return
        }
      }
    }
  }

  get isInfoEmpty () {
    let result = false
    if (this.userInput.name.length === 0) {
      this.setIsNameEmpty(true)
      result = true
    }

    if (this.userInput.phone.length === 0) {
      this.setIsPhoneEmpty(true)
      result = true
    }

    if (this.userInput.comment.length === 0) {
      this.setIsCommentEmpty(true)
      result = true
    }

    if (this.isFileLarge) {
      result = true
    }

    if (this.isFileExtForbidden) {
      result = true
    }

    return result
  }

  saveLeadOnServer () {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const that = this
    that.changeSaveButtonDisabled(true)

    const formData = new FormData() // Needed for potential file upload
    formData.append('user_input[name]', this.userInput.name)
    formData.append('user_input[vendor_id]', this.userInput.vendor_id)
    formData.append('user_input[countryCode]', this.userInput.countryCode)
    formData.append('user_input[phone]', this.userInput.phone)
    formData.append('user_input[comment]', this.userInput.comment)

    if (this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        formData.append('files[]', this.files[i])
      }
    }

    fetch(this.serverPath, {
      method: this.serverMethod,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      body: formData
    })
      .then(response => {
        if (response.ok) {
          that.navigateToSuccessfullyPage()
        } else {
          that.changeSaveButtonDisabled(false)
          return response.json().then(data => {
            if (response.status === 422) {
              if (data.errors?.name) {
                this.setIsNameEmpty(true)
              }
              if (data.errors?.phone) {
                this.setIsPhoneEmpty(true)
              }
            } else {
              throw new Error('Error: Something went wrong')
            }
          })
        }
      })
      .catch(error => {
        that.changeSaveButtonDisabled(false)
        throw new Error(error.message)
      })
  }

  navigateToSuccessfullyPage () {
    location.href = this.successfullyPath
  }
}

export default VendorPageModel
