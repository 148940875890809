import React, { FC } from 'react'
import ServicesDealsItem from './SevicesDealsItem'

interface ISevicesDealsListProps {
    deals: any
    length: number
}

const SevicesDealsList: FC<ISevicesDealsListProps> = ({ deals, length }) => {
  return (
        <div className='d-flex flex-column align-items-center gap-5'>
            <div className='services_deals mt-5 d-flex gap-4 justify-content-center align-items-center flex-wrap'>
                {deals.slice(0, length).map((deal: any) =>
                    <ServicesDealsItem deal={deal} key={`supplier_popular_${deal.id}`} />
                )}
            </div>
            <button className="black_button">
                Смотреть все
            </button>
        </div>
  )
}

export default SevicesDealsList
