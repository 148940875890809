import React, { FC } from 'react'
import PopularServicesItem from './PopularServicesItem'
import LargePlus from '../../../../../assets/images/vendors/services_landing_stock/icons/large_plus.svg'
import { search_with_params_vendors_path } from '../../../../routes.js.erb'

interface IPopularServicesListProps {
    locale: string
    length: number
    popularVendors: any
    locationSlug: string
    categorySlug: string
}

const PopularServicesList: FC<IPopularServicesListProps> = ({ locale, length, popularVendors, locationSlug, categorySlug }) => {
  return (
        <div className='services_list mt-5 d-flex gap-4 flex-wrap justify-content-between'>
            {popularVendors.slice(0, length).map((vendor: any) =>
                <PopularServicesItem vendor={vendor} key={`supplier_popular_${vendor.id}`}/>
            )}
            <a href={search_with_params_vendors_path(locale, locationSlug, categorySlug)}>
                <div className="see_more_block d-flex flex-column align-items-center gap-4">
                    <h3>Смотреть еще 454 типографии</h3>

                    <img src={LargePlus} alt="See more"/>
                </div>
            </a>
        </div>
  )
}

export default PopularServicesList
