import React, { FC } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Vendor } from './types/Vendor.type'
import { FormattedMessage } from 'react-intl'
import { vendor_path } from '../../../routes.js.erb'

interface IVendorServiceItem {
    isPromo?: boolean
    vendor: Vendor;
    location: any
}

const VendorServiceItem: FC<IVendorServiceItem> = ({ isPromo, vendor, location }) => {
  const redirectToVendorPage = (slug: string) => {
    window.location.href = vendor_path(LOCALE, slug)
  }

  return (
        <div onClick={() => redirectToVendorPage(vendor.slug)} className={isPromo ? 'search_service_card promo' : 'search_service_card'}>
            <div className="search_service_img">
                <img src={vendor.image_url} alt="ServiceImg"/>
            </div>
            <div className="search_service_info">
                <div className="search_service_header d-flex">
                    <img width='40px' height='40px' className='service_logo' alt='CakeLogo' src={vendor.thumbnail_url}/>
                    <div className='ms-2'>
                        <h6>{vendor.name}</h6>
                    </div>
                    <span className='advertisement align-self-start ms-auto'>
                <FormattedMessage id={`locations.${location.translation_key}`} defaultMessage={location.name_default}/>
                </span>
                </div>
                <div className="search_service_description mt-3">{ReactHtmlParser(vendor.rich_description.body)}</div>
            </div>
            {isPromo &&
                <div className="promo_info pt-1 pb-1">
                    Промо
                </div>
            }
        </div>
  )
}

export default VendorServiceItem
