import { action, makeObservable, observable } from 'mobx'
import { loc_cat_landing_vendors_path } from '../../../../routes.js.erb'

interface IVendorsLandingModelProps {
    popularVendors: any
    locale: string
    categories: any
    locations: any
    categorySlug: string
    locationSlug: string
}

class VendorsLandingModel {
  popularVendors: any
  locale: string
  categories: any
  locations: any
  categorySlug: string
  locationSlug: string
  selectedCategory: string
  selectedLocation: string

  constructor ({ locale, popularVendors, categories, locations, categorySlug, locationSlug }: IVendorsLandingModelProps) {
    this.locale = locale
    this.popularVendors = popularVendors

    this.categories = categories
    this.locations = locations
    this.categorySlug = categorySlug
    this.locationSlug = locationSlug

    this.selectedCategory = this.categorySlug
    this.selectedLocation = this.locationSlug

    makeObservable(this, {
      selectedCategory: observable,
      selectedLocation: observable,
      setSelectedCategory: action,
      setSelectedLocation: action
    })
  }

  setSelectedCategory (category: string) {
    this.selectedCategory = category
  }

  setSelectedLocation (location: string) {
    this.selectedLocation = location
  }

  redirectToNewPage () {
    location.href = loc_cat_landing_vendors_path(LOCALE, this.selectedLocation, this.selectedCategory)
  }
}

export default VendorsLandingModel
