import { action, makeObservable, observable } from 'mobx'
import { Vendor } from '../types/Vendor.type'
import { search_with_params_vendors_path } from '../../../../routes.js.erb'

interface IVendorSearchModelProps {
    vendors: Vendor[]
    location: any
    category: any
    totalPages: number
}

class VendorsSearchModel {
  isSortModalOpen: boolean
  vendors: Vendor[]
  location: any
  category: any
  totalPages: number
  searchParams: URLSearchParams
  currentPage: number

  constructor ({ vendors, location, category, totalPages }: IVendorSearchModelProps) {
    this.isSortModalOpen = false

    this.vendors = vendors
    this.location = location
    this.category = category
    this.totalPages = totalPages

    this.searchParams = new URLSearchParams(window.location.search)
    this.currentPage = parseInt(this.searchParams.get('page') || '1')

    makeObservable(this, {
      isSortModalOpen: observable,
      currentPage: observable,
      setIsSortModalOpen: action,
      setCurrentPage: action
    })
  }

  setIsSortModalOpen (isOpen: boolean) {
    this.isSortModalOpen = isOpen
  }

  setCurrentPage (page: number) {
    this.currentPage = page
    location.href = search_with_params_vendors_path(LOCALE, this.location.slug, this.category.slug, { page })
  }
}

export default VendorsSearchModel
